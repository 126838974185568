<template>
  <div :style="{background: $vuetify.theme.themes.light.background}">
    <v-card class="m-auto" style="max-width: 434px">
      <v-card-title>
        Login
      </v-card-title>
      <v-card-text>
        <v-row class="mx-0 mt-6">
          <v-text-field v-model="username" outlined placeholder="ชื่อผู้ใช้งาน" height="32px" hide-details/>
        </v-row>
        <v-row class="mx-0 mt-8">
          <v-text-field v-model="password" type="password" outlined placeholder="รหัสผ่าน" height="32px" hide-details/>
        </v-row>

        <v-row class="mx-0 mt-8 justify-end mb-2">
          <v-btn @click="login" color="primary">ลงชื่อเข้าใช้งาน</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: ""
    }
  },
  created() {
    this.$store.state.isLoading = false
  },
  methods: {
    login() {
      this.$store.state.isLoading = true
      let payload = {
        username: this.username,
        password: this.password,
        // username: 'admin',
        // password: 'admin@kmitl',
      }
      axios.post('/api/auth/obtain/', payload)
          .then(async res => {
            await this.$store.commit("updateToken", res.data.access)
            this.$store.state.isLoading = false
            // axios.interceptors.request.use(
            //     (config) => {
            //       let token = this.$store.state.jwt;
            //       if (token) {
            //         config.headers['Authorization'] = `Bearer ${token}`;
            //         config.headers['Content-Type'] = 'application/json'
            //         config.xsrfCookieName = 'csrftoken';
            //         config.xsrfHeaderName = 'X-CSRFToken';
            //       }
            //       return config;
            //     },
            //     (error) => {
            //       return Promise.reject(error);
            //     }
            // );
            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$store.state.jwt}`
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            axios.defaults.xsrfCookieName = 'csrftoken';
            axios.defaults.xsrfHeaderName = 'X-CSRFToken';
            axios.get('/api/accounts/user/',
                //     {
                //   headers: {
                //     Authorization: `Bearer ${res.data.access}`,
                //     'Content-Type': 'application/json'
                //   },
                // }
            ).then(async res => {
              await this.$store.commit("setAuthUser", {
                authUser: res.data.data,
                isAuthenticated: true,
              },)
              await this.$router.push({path: '/'})
            }).catch(e => {
              this.$store.state.snackbar = {
                text: 'เข้าสู่ระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
                visible: true,
                color: 'error'
              }
            })

            // window.location.reload();
          })
          .catch(e => {
            this.$store.state.isLoading = false
            this.$store.state.snackbar = {
              text: 'เข้าสู่ระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
            console.error(e)
          })
          .finally(() => this.$store.state.isLoading = false)
    }
  }
}
</script>

<style scoped>


</style>